<template>
  <ln-app style="overflow: hidden;">
    <router-view/>
  </ln-app>
  <ln-footer primary height="50px">
    <ln-container>
      <ln-col>
        <a href="/imprint" class="link">Impressum</a>
        <a href="/privacy-policy" class="link">Datenschutzerklärung</a>
      </ln-col>
    </ln-container>
  </ln-footer>
  <ln-cookie-consent primary-light xl="4" lg="6" md="8" sm="12" xs="16">
    <ln-cookie-consent-title>
      <ln-container>
        <ln-row space>
          Diese Seite benutzt Cookies
        </ln-row>
      </ln-container>
    </ln-cookie-consent-title>
    <ln-cookie-consent-content>
      <ln-container>
        <ln-row space wrap>
          <p>Um die reibungslose Nutzng der Webseite zu gewährleisten, nutzten wir Cookies. Diese helfen uns, die
            Webseite zu betreiben. Des weiteren nutzen wir Cookies von Dritten, die es uns ermöglichen, unseren Dienst
            anzubieten, stetig zu verbessern und Werbeanzeigen personalisiert auf unserer Webseite anzuzeigen.
            Sie können nur die notwendigen Cookies aktivieren, oder weitere Cookies zulassen.
            Diese Einstellung können sie jederzeit am unteren Bildschirmrand auf dem Einstellungssymbol ändern.</p>
          <p>Weiteres zu unserem Datenschutz finden sie in unserer <a href="/privacy-policy" class="link-black">Datenschutzerklärung</a></p>
        </ln-row>
      </ln-container>
    </ln-cookie-consent-content>
    <ln-cookie-consent-actions space>
      <ln-row align="center" justify="space-around">
        <a @click="() => $eventBus.emit('accept-essentials')" class="link-black">Nur notwendige Cookies verwenden</a>
        <ln-button xs="8" lg="4" xl="3" secondary @click="() => $eventBus.emit('accepted')">Cookies zulassen</ln-button>
      </ln-row>
    </ln-cookie-consent-actions>
  </ln-cookie-consent>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-image: url(https://www.instpic.de/large/aa3053fd-0a32-4c00-be87-dc9c14c1d8b1);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

}
nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.link {
  color: white;
  text-decoration: none;
  padding: 10px;
  align-items: center;
  display: flex;
}
.link-black {
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
</style>
