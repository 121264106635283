import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { LnMaterialsPlugin } from "@ln-cloud/ln-materials";

createApp(App).use(store).use(router).use(LnMaterialsPlugin, {
    colors: {
        primary: '#03A9F4',
        primaryLight: '#B3E5FC',
        primaryDark: '#0288D1',
        secondary: '#37474f',
        secondaryLight: '#e8e8e8',
        secondaryDark: '#102027'
    },
    fontFamily: '"Montserrat", Avenir, Helvetica, Arial, sans-serif;'
}).mount('#app')
