
import { Options, Vue } from 'vue-class-component';

@Options({
  data() {
    return {
      adsenseContent: ''
    }
  },
  mounted() {
    this.adsenseContent = document.getElementById('divadsensedisplaynone')?.innerHTML
  }
})
export default class HomeView extends Vue {}
